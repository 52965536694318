// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-apps-bus-timetable-privacy-policy-tsx": () => import("./../../../src/pages/apps/bus-timetable/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-apps-bus-timetable-privacy-policy-tsx" */),
  "component---src-pages-apps-bus-timetable-terms-of-service-tsx": () => import("./../../../src/pages/apps/bus-timetable/terms-of-service.tsx" /* webpackChunkName: "component---src-pages-apps-bus-timetable-terms-of-service-tsx" */),
  "component---src-pages-apps-south-gloucestershire-bin-day-privacy-policy-tsx": () => import("./../../../src/pages/apps/south-gloucestershire-bin-day/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-apps-south-gloucestershire-bin-day-privacy-policy-tsx" */),
  "component---src-pages-apps-south-gloucestershire-bin-day-terms-of-service-tsx": () => import("./../../../src/pages/apps/south-gloucestershire-bin-day/terms-of-service.tsx" /* webpackChunkName: "component---src-pages-apps-south-gloucestershire-bin-day-terms-of-service-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

